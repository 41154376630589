import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useInterval from 'react-useinterval';

import Framework from '../components/framework';
import Logo from '../components/framework/logo';
import Seo from '../components/framework/seo';
import Time from '../components/framework/time';

const LocationItem = ({ data, location }) => {
	const isBrowser = () => typeof window !== 'undefined';
	const location_item = data.location;
	const services = data.allService.nodes;
	const types = [...new Set(location_item.products.map((value) => value.type))];
	const [isOnline, setNetwork] = useState(
		isBrowser() ? window.navigator.onLine : true
	);
	const reload_time = 3 * 60 * 60 * 1000; // 3 hours

	const updatePage = () => {
		if (isOnline) {
			window.location.reload(true);
		}
	};

	const updateNetwork = () => {
		setNetwork(window.navigator.onLine);
	};

	useEffect(() => {
		window.addEventListener('offline', updateNetwork);
		window.addEventListener('online', updateNetwork);
		return () => {
			window.removeEventListener('offline', updateNetwork);
			window.removeEventListener('online', updateNetwork);
		};
	});

	useInterval(updatePage, reload_time);

	return (
		<Framework location={location}>
			<Seo title={`${location_item.name}`} />
			<div className="container-fluid overflow-hidden">
				<div className="row justify-content-between g-5">
					<div className="col-7">
						<div className="">
							{services.map((service, index) => {
								if (service.partner.id !== 'testmottagningen') return;
								if (!types.includes(service.product.type)) return;

								return (
									<span key={index}>
										<hr className="border-light my-0" />
										<div className="align-items-center row justify-content-between my-vh-1 o-hidden">
											<div className="col-12 slide-in-bottom-d2">
												<h3 className="title-big text-white">
													{service.name.toUpperCase()}
												</h3>
											</div>
											<div className="col-5 slide-in-bottom-d2">
												<p className="text-big mb-0 text-white">
													{service.product.type == 'antibody' && (
														<>We measure your value</>
													)}
													{(service.product.type == 'antigen' ||
														service.product.type == 'pcr') && (
														<>+ travel certificate</>
													)}
												</p>
												<p className="text-big text-primary-light text-white">
													<Time
														format={service.time_format}
														number={service.time_result}
													/>
												</p>
											</div>
											<div className="col-7 text-end slide-in-bottom-d2">
												<p className="title-big text-white">
													{service.price_from} {service.currency.code}
												</p>
											</div>
										</div>
									</span>
								);
							})}
						</div>
					</div>
					<div className="col-4">
						<div className="row my-vh-1">
							<div className="col"></div>
							<div className="col-auto">
								<Logo />
							</div>
						</div>

						<div className="">
							<h1 className="fs-3 title-loc mb-4 mt-4 text-white">
								{location_item.name}
							</h1>

							<h2 className="text-big text-primary-light">Opening hours</h2>
							<p className="title-date mb-0 text-white">
								{location_item.opening_hours_weekdays} Weekdays
							</p>
							<p className="title-date text-white">
								{location_item.opening_hours_weekends} Weekends
							</p>
						</div>
						<div className="row mt-5 pt-5">
							<h2 className="text-big text-white fw-500">
								Book your appointment at <br />
								testmottagningen.se
							</h2>
						</div>
					</div>
				</div>
			</div>
		</Framework>
	);
};

LocationItem.propTypes = {
	location: PropTypes.object.isRequired,
};

export default LocationItem;

export const query = graphql`
	query LocationBySlug($slug: String!) {
		allService(filter: { active: { eq: true } }, sort: { fields: name }) {
			nodes {
				currency {
					code
				}
				name
				partner {
					id
				}
				price_from
				product {
					type
				}
				time_format
				time_result
			}
		}

		location(slug: { eq: $slug }) {
			id
			name
			opening_hours_weekdays
			opening_hours_weekends
			products {
				type
			}
		}
	}
`;
