import PropTypes from 'prop-types';
import React from 'react';

import { ClockHistory } from 'react-bootstrap-icons';

const Time = ({ format, number }) => {
	if (!format) return <></>;

	return (
		<>
			<ClockHistory />
			<span className="ms-1">
				{format == 'hour' && <>Result within&#160;{number}&#160;hours</>}
				{format == 'same_day' && <>Result same day</>}
			</span>
		</>
	);
};

Time.propTypes = {
	format: PropTypes.string,
	number: PropTypes.number,
};

export default Time;
