import React from 'react';

import logo from '../../images/logo.svg';

const Logo = () => {
	return (
		<a href="/">
			<img alt="Logo" className="logo mb-4" src={logo} />
		</a>
	);
};

export default Logo;
